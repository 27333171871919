.eva-chip {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eva-chip .eva-chip__text {
  margin-left: 10px;
}
.eva-chip:hover .eva-chip__text {
  color: #3e4c5d !important;
}
.eva-chip:hover .eva-chip__button {
  color: #3e4c5d !important;
}
.eva-chip .prewiev-img-background {
  border: 1px solid #495a6f;
  border-radius: 5px;
  width: 52px;
  height: 52px;
}
.eva-chip .prewiev-img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 5px;
}
