
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-switch {
  display: flex;
  column-gap: 10px;
  user-select: none;

  .switch-rectangle {
    width: 42px;
    height: 24px;
    border-radius: 12px;
    border: 1.5px;
    background: #7B8B9D;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 3px;

    &.active {
      background: #0094FF;
      transition: background 0.3s;
    }

    .switch-toggle {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background: #E4E9EF;
      transition: transform 0.3s;

      &.active {
        transform: translateX(18px);
      }
    }
  }
}
