
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-chip {
  display: flex;
  flex-direction: row;
  align-items: center;


  .eva-chip__text {
    margin-left: 10px;
  }

  &:hover {
    .eva-chip__text {
      color: #3e4c5d !important;

    }

    .eva-chip__button {
      color: #3e4c5d !important;
    }
  }

  .prewiev-img-background {
    border: 1px solid #495a6f;
    border-radius: 5px;
    width: 52px;
    height: 52px;
  }

  .prewiev-img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
  }
}
