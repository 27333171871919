.eva-switch {
  display: flex;
  column-gap: 10px;
  user-select: none;
}
.eva-switch .switch-rectangle {
  width: 42px;
  height: 24px;
  border-radius: 12px;
  border: 1.5px;
  background: #7B8B9D;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 3px;
}
.eva-switch .switch-rectangle.active {
  background: #0094FF;
  transition: background 0.3s;
}
.eva-switch .switch-rectangle .switch-toggle {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: #E4E9EF;
  transition: transform 0.3s;
}
.eva-switch .switch-rectangle .switch-toggle.active {
  transform: translateX(18px);
}
